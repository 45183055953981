<template>
  <div>
    <div class="author">
      <author-bar
        :author_name="authorProfile.author_name"
        :source_name="authorProfile.source_id"
        :total_messages="authorProfile.total_messages"
        :num_conversations="authorProfile.num_conversations"
        :first_posted="authorProfile.first_posted"
        :last_posted="authorProfile.last_posted"
      >
      </author-bar>

      <v-row>
        <sort-list :items="items" v-model="selected"></sort-list>
      </v-row>
      <v-row style="margin-left: 0em; margin-top: -3em">
        <conversation-list
          :conversations="authorMessages"
          :isPermalink="false"
          :isAuthor="true"
          :isMessages="true"
          v-model="panel"
          class="mt-6"
        ></conversation-list>
      </v-row>
      <v-fade-transition>
        <v-btn
          v-scroll="onScroll"
          v-show="fab"
          fab
          dark
          fixed
          bottom
          right
          outlined
          color="white"
          @click="toTop"
        >
          <v-icon>mdi-arrow-up-bold</v-icon>
        </v-btn>
      </v-fade-transition>
      <infinite-loading
        class="mt-16"
        forceUseInfiniteWrapper
        :identifier="infiniteId"
        @infinite="infiniteHandler"
        v-if="authorMessages.length > 19"
      >
      </infinite-loading>
      <v-snackbar v-model="snackBar" color="#112748" elevation="6">
        All messages loaded
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import store from "../store/index";
import InfiniteLoading from "vue-infinite-loading";
import AuthorBar from "@/components/AuthorBar.vue";
import ConversationList from "@/components/ConversationList.vue";
import SortList from "@/components/SortList.vue";

export default {
  name: "ThreatActorProfile",
  components: {
    AuthorBar,
    ConversationList,
    SortList,
    InfiniteLoading,
  },
  data: () => ({
    panel: [],
    selected: "Most Recent",
    items: ["Most Recent", "Oldest"],
    fab: false,
    snackBar: false,
    stopLoading: false,
    infiniteId: 1,
  }),
  beforeCreate() {
    store.commit("darkRecon/clearConversation");
  },
  beforeMount() {
    store.dispatch("darkRecon/getAuthorProfile", [
      this.$route.params.networkId,
      this.$route.params.sourceId,
      this.$route.params.authorId,
    ]);
  },
  mounted() {
    this.toTop();
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 100;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    infiniteHandler($state) {
      if (this.stopLoadingAuthorMessages == true) {
        setTimeout(function () {
          $state.loaded();
          return;
        }, 200);
      }
      store
        .dispatch("darkRecon/addAuthorProfileMessages", [
          this.$route.params.networkId,
          this.$route.params.sourceId,
          this.$route.params.authorId,
        ])
        .then((result) => {
          if (result === null) {
            $state.loaded();
            return;
          }
          if (result.length > 0) {
            $state.loaded();
            return;
          }
          if (result.length === 0) {
            $state.complete();
            return;
          }
        });
    },
  },
  computed: {
    authorProfile() {
      return store.state.darkRecon.threatActorProfile;
    },
    authorMessages() {
      return store.state.darkRecon.threatActorMessages;
    },
    stopLoadingAuthorMessages() {
      return store.state.darkRecon.stopLoadingAuthorMessages;
    },
  },
  watch: {
    selected: {
      handler: function (val) {
        console.log("sort selection: ", val);
        let keyVal;
        if (val == "Most Recent") {
          keyVal = "desc";
        }
        if (val == "Oldest") {
          keyVal = "asc";
        }
        store.commit("darkRecon/replaceValue", {
          key: "sortOrderAuthorProfile",
          value: keyVal,
        });
        store.dispatch("darkRecon/getAuthorProfile", [
          this.$route.params.networkId,
          this.$route.params.sourceId,
          this.$route.params.authorId,
        ]);
      },
    },
  },
};
</script>

<style scoped>
.author >>> .v-icon.v-icon.v-icon--link:nth-child(1) {
  display: none;
}
.author >>> .conversation-list {
  margin: 0px 0px 0px 0px;
  font-family: "Roboto";
  width: 100%;
  max-width: 966px;
}
</style>
