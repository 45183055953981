<template>
  <div class="author-bar">
    <v-alert
      height="120"
      dark
      icon="mdi-skull"
      prominent
      class="mt-8"
      max-width="960"
    >
      <v-row class="d-flex">
        <v-col>
          <span class="barData"
            >{{ author_name }}<br /><span
              class="barDataBottom caption text-uppercase"
              >{{ source_name }}</span
            ></span
          >
        </v-col>
        <v-col cols="1"> </v-col>
        <v-col>
          <span class="barData" style="font-weight: 300"
            >{{ kFormatterDecimal(total_messages)
            }}<span class="barDataBottom caption text-uppercase"
              >Total Messages</span
            ></span
          >
        </v-col>
        <v-col>
          <span class="barData" style="font-weight: 300">
            <span v-if="Number.isInteger(num_conversations)">{{
              num_conversations
            }}</span>
            <span v-if="!Number.isInteger(num_conversations)">{{
              num_conversations.toFixed(1)
            }}</span>
            <span class="barDataBottom caption text-uppercase"
              >Conversations</span
            >
          </span>
        </v-col>
        <v-col style="line-height: 0em; white-space: pre-line; margin-top: 2px">
          <span class="caption">{{ formatDate(first_posted) }}</span
          ><br />
          <span class="caption">{{ formatDate(last_posted) }}</span
          ><span class="barDataBottom caption text-uppercase">Activity</span>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common";
export default {
  name: "author-bar",
  data: () => ({
    formatDate: formatDate,
  }),
  props: {
    total_messages: {
      type: Number,
      default: 0,
    },
    num_conversations: {
      type: Number,
      default: 0,
    },
    first_posted: {
      type: String,
      default: "",
    },
    last_posted: {
      type: String,
      default: "",
    },
    author_name: {
      type: String,
      default: "",
    },
    source_name: {
      type: String,
      default: "",
    },
  },
  methods: {
    kFormatterDecimal(n) {
      if (n < 1e3) return n;
      if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
      if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
      if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
      if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    },
  },
};
</script>
<style scoped>
.author-bar >>> .v-alert--prominent .v-alert__icon.v-icon {
  font-size: 80px;
  color: #58abe7;
}
.author-bar >>> .v-alert--prominent .v-alert__icon.v-icon::after {
  opacity: 0;
}
.author-bar >>> .v-alert--prominent .v-alert__wrapper {
  margin-top: 0.5em;
}
.barData {
  font-size: 1.75em;
  font-weight: 100;
}
.barDataBottom {
  display: flex;
  align-self: center;
  color: #d5d5d5;
}
</style>
